import cn from 'classnames';
import Wrapper from 'components/Wrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import ProgressRing from '../../CavesIndexTpl/ProgressRing';
import * as styles from './Clip.module.scss';

class Clip extends Component {
	constructor(props) {
		super(props);

		this.state = {
			url: '',
			playing: false,
			played: 0,
			activeSoundIndex: null,
			previewPlaying: false,
		}
	}

	handlePauseClip = () => this.setState({ playing: false });

	componentDidUpdate(prevProps) {
		if (this.props.isOpen !== prevProps.isOpen) {
			if (this.props.isOpen) {
				this.setState(() => {
					return {
						previewPlaying: true
					}
				});
			} else {
				this.setState(() => {
					return {
						previewPlaying: false,
						playing: false,
						played: 0,
						url: '',
						activeSoundIndex: null
					}
				});
			}
		}
	}
	
	handlePlayPauseClip(index) {
		const { data: { sounds } } = this.props;
		const thisUrl = sounds[index].sound;
		this.setState((state) => {
			if (state.url === thisUrl) {
				return {
					url: thisUrl,
					playing: !this.state.playing,
					activeSoundIndex: index,
					previewPlaying: false,
				}
			} else {
				return {
					url: thisUrl,
					playing: true,
					activeSoundIndex: index,
					played: 0,
					previewPlaying: false
				}
			}
		});
	}

	handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
	}
	
	handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
	}

	onMouseMove = e => {
		const cursor = document.querySelector('#cursor');
		cursor.style.left = `${e.pageX}px`;
		cursor.style.top = `${e.pageY}px`;
		cursor.style.display = 'block';
	}
	onMouseLeave = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'none';
	}
	onMouseEnter = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'block';
	}
	
	render() {
		const { data: { title, previewSound, position, video, sounds }, isOpen, close } = this.props;
		const { url, playing, played, activeSoundIndex, previewPlaying } = this.state;
		return (
			<div className={cn(styles.root, {
				[styles.isOpen]: isOpen,
			})}>
				<ReactPlayer url={video} playing={true} className={styles.video} muted={true} loop={true} width="100%" height="auto" />
				<div className="btnClose btnFixed" onClick={close}></div>
				<Wrapper size="Xl" className={styles.wrapper}>
					<h1 className={styles.title}>{title}</h1>
					<section>
						<ul className={styles.sounds}>
							{
								sounds.map(({ heading }, index) => (
									<li
										key={index}
										className={styles.sound}
										style={position[index]}
										onClick={() => this.handlePlayPauseClip(index)}
										onMouseMove={this.onMouseMove}
										onMouseLeave={this.onMouseLeave}
										onMouseEnter={this.onMouseEnter}
									>
										<div className={styles.background}>
										</div>
										<div className={cn(styles.soundProgress, {
											[styles.isActive]: (index === activeSoundIndex)
										})}>
											<ProgressRing progress={played * 100} />
										</div>
										<span className={cn(styles.ripple, {
											[styles.isActive]: (index === activeSoundIndex && playing) || previewPlaying
										})} />
										<h3 className={cn(styles.soundDesc, {
											[styles.isActive]: (index === activeSoundIndex)
										})}>
											{heading}
										</h3>
									</li>
								))
							}
						</ul>
					</section>
					<div>
						<ReactPlayer
							url={url}
							playing={playing}
							controls={false}
							loop={true}
							onProgress={this.handleProgress}
							volume={0.8}
							progressInterval={200}
							width={0}
							height={0}
						/>
						<ReactPlayer
							url={previewSound}
							playing={previewPlaying}
							controls={false}
							loop={true}
							volume={0.5}
							width={0}
							height={0}
						/>
					</div>
				</Wrapper>
			</div>
		)
	}
}

Clip.propTypes = {
	data: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
};
Clip.defaultProps = {
	isOpen: false,
}

export default Clip;