import Wrapper from 'components/Wrapper';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './CaveBanner.module.scss';

const CaveBanner = ({ heading, desc, image, className }) => {
	return (
		<section className={className}>
			<Wrapper size="L"className={styles.root} >
				<div className={styles.text}>
					<h1 className={styles.heading}>{heading}</h1>
					<h2 className={styles.desc}>{desc}</h2>
				</div>
				<div className={styles.img}>
					<img src={image.publicURL} alt={heading} />
				</div>
			</Wrapper>
		</section>
	);
};

CaveBanner.propTypes = {
	heading: PropTypes.string.isRequired,
	desc: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
	className: PropTypes.string,
}

export default CaveBanner;