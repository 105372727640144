import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import * as styles from './ProgressRing.module.scss';

class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, strokeWidth } = this.props;

    this.normalizedRadius = radius - strokeWidth / 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
	}
	render() {
		const { radius, strokeWidth, progress, className, color } = this.props;
		const strokeDashoffset = this.circumference * (1 - progress / 100);
	
		return (
			<svg
				height={radius * 2}
				width={radius * 2}
				viewBox={'0 0 ' + radius * 2 + ' ' + radius * 2}
				className={cn(className, styles.root)}
				>
				<defs>
					<filter width={radius * 2} height={radius * 2} x="0" y="0" id="blur" filterUnits="userSpaceOnUse">
						<feGaussianBlur stdDeviation="2.5" />
					</filter>
				</defs>
				<circle
					stroke={color}
					fill="transparent"
					strokeWidth={strokeWidth}
					strokeDasharray={this.circumference + ' ' + this.circumference}
					strokeDashoffset={strokeDashoffset}
					r={this.normalizedRadius}
					cx={radius}
					cy={radius}
					className={styles.progress}
				/>
				<circle
					stroke={color}
					fill="transparent"
					strokeWidth={4}
					strokeDasharray={this.circumference + ' ' + this.circumference}
					strokeDashoffset={strokeDashoffset}
					r={this.normalizedRadius}
					cx={radius}
					cy={radius}
					filter="url('#blur')"
					className={styles.progress}
				/>
			</svg>
		);
	}
}

ProgressRing.propTypes = {
	radius: PropTypes.number.isRequired,
	strokeWidth: PropTypes.number.isRequired,
	progress: PropTypes.number.isRequired,
	className: PropTypes.string,
	color: PropTypes.string.isRequired,
}

ProgressRing.defaultProps = {
	strokeWidth: 4,
	radius: 50,
	progress: 0,
	color: '#cccccc'
}

export default ProgressRing;