import Layout from 'components/Layout';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import MineIndexTpl from 'templates/Caves/MineIndexTpl';

const Tpl = props => (
  <Layout location={props.location}>
    <MineIndexTpl {...props} />
  </Layout>
);
const MineIndex = props => (
  <StaticQuery
    query={graphql`
      query MineQuery {
        minePage: mineJson {
          title
          meta {
            description
          }
          heading
          description
          image {
            publicURL
          }
          lists {
            title
            previewSound
            position {
              top
              left
            }
            video
            sounds {
              heading
              sound
            }
          }
          footer {
            bgImage {
              publicURL
            }
            left {
              heading
              icon {
                publicURL
              }
            }
            right {
              heading
              image {
                publicURL
              }
              icon {
                publicURL
              }
            }
            goCaveLink
          }
        }
      }
    `}
    render={data => <Tpl data={data} {...props} />}
  />
);
Tpl.propTypes = {
  location: PropTypes.object,
};

export default MineIndex;