// extracted by mini-css-extract-plugin
export var atRipple = "Clip-module--at-ripple--7WU4a";
export var background = "Clip-module--background--J7jIT";
export var isActive = "Clip-module--isActive--fDea4";
export var isOpen = "Clip-module--isOpen--GegXq";
export var ripple = "Clip-module--ripple--YC3x8";
export var root = "Clip-module--root--TSgqw";
export var sound = "Clip-module--sound--znH27";
export var soundDesc = "Clip-module--soundDesc--NXPu8";
export var soundProgress = "Clip-module--soundProgress--G62SW";
export var title = "Clip-module--title--Uhfnu";
export var video = "Clip-module--video--4SAIY";
export var wrapper = "Clip-module--wrapper--m2dCq";