import cn from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as styles from './CaveFooter.module.scss';

class CaveFooter extends Component {
	constructor(props) {
		super(props);
	}
	onMouseMove = e => {
		const cursor = document.querySelector('#cursor');
		cursor.style.left = `${e.pageX}px`;
		cursor.style.top = `${e.pageY}px`;
		cursor.style.display = 'block';
	}
	onMouseLeave = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'none';
	}
	onMouseEnter = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'block';
	}
	render() {
		const { data: { bgImage, left, right, goCaveLink }, className } = this.props;
		return (
			<footer
				className={cn(styles.footer, className)}
				style={{ backgroundImage: `url('${bgImage.publicURL}')` }}
				onMouseMove={this.onMouseMove}
				onMouseLeave={this.onMouseLeave}
				onMouseEnter={this.onMouseEnter}
			>
				<ul className={styles.root}>
					<li className={cn(styles.left, styles.item)}>
						<Link className={styles.link} to="/projects/caves">
							<div><img src={left.icon.publicURL} className={styles.icon} /></div>
							<div>{left.heading}</div>
						</Link>
					</li>
					<li className={cn(styles.right, styles.item)}>
						<Link className={styles.link} to={goCaveLink}>
							<div><img src={right.image.publicURL} className={styles.image} /></div>
							<div>{right.heading}</div>
							<div><img src={right.icon.publicURL} className={styles.icon} /></div>
						</Link>
					</li>
				</ul>
			</footer>
		);
	}
}

CaveFooter.propTypes = {
	data: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default CaveFooter;