import Wrapper from 'components/Wrapper';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Clip from '../Clip';
import * as styles from './ClipsIndex.module.scss';

class ClipsIndex extends Component {
	constructor() {
		super();
		this.closeClip = this.closeClip.bind(this);
		this.state = {
			clipIsOpen: false,
			clipIndex: 0,
		}
	}

	closeClip() {
		return this.setState({ clipIsOpen: false });
	}
	openClip(index) {
		return this.setState({
			clipIsOpen: true,
			clipIndex: index,
		});
	}


	onMouseMove = e => {
		const cursor = document.querySelector('#cursor');
		cursor.style.left = `${e.pageX}px`;
		cursor.style.top = `${e.pageY}px`;
		cursor.style.display = 'block';
	}
	onMouseLeave = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'none';
	}
	onMouseEnter = () => {
		const cursor = document.querySelector('#cursor');
		cursor.style.display = 'block';
	}


	render() {
		const { lists, className } = this.props;
		const { clipIndex, clipIsOpen } = this.state;
		return (
			<div className={className}>
				<Clip data={lists[clipIndex]} isOpen={clipIsOpen} close={this.closeClip} />
				<Wrapper size="Xl">
					<ul className={styles.clips}>
					{ lists.map((data, i) => (
						<li
							key={i}
							className={styles.clip}
							onClick={() => this.openClip(i)}
							onMouseMove={this.onMouseMove}
							onMouseLeave={this.onMouseLeave}
							onMouseEnter={this.onMouseEnter}
						>
							<h2 className={styles.clipTitle}>{data.title}</h2>
						</li>
					)) }
					</ul>
				</Wrapper>
			</div>
		)
	}
}

ClipsIndex.propTypes = {
	lists: PropTypes.array.isRequired,
	className: PropTypes.string,
}

export default ClipsIndex;