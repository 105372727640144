import Metadata from 'components/Metadata';
import PropTypes from 'prop-types';
import React from 'react';
import CaveBanner from '../CavesIndexTpl/CaveBanner';
import CaveFooter from '../CavesIndexTpl/CaveFooter';
import ClipsIndex from './ClipsIndex';
import * as styles from './MineIndexTpl.module.scss';

const MineIndexTpl = ({ data }) => {
  const {
    minePage: {
      title,
      meta: { description },
      heading,
      description: desc,
      image,
      lists,
      footer
    },
  } = data;
  return (
    <div>
      <Metadata title={title} description={description} />
      <div className={styles.wrapper}>
        <CaveBanner desc={desc} image={image} heading={heading} className={styles.banner} />
        <ClipsIndex lists={lists} className={styles.clips} />
        <CaveFooter data={footer} className={styles.footer} />
      </div>
    </div>
  );
};

MineIndexTpl.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MineIndexTpl;
